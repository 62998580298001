import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {FormatNumber, ImagesUrl, ServerUrl,  Token, config, API_URL} from '../component/include'

import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { formatGroupLabel, getBritishDate, getNumWorkDays, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const DisplayStudentAttendance =()=> {
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
    const [sqlQuery, setSqlQuery] = useState([]);
    const [examtype, setExamtype]=useState([])
const [isSubmitting, setIsSubmitting] = useState(false); 
    const [schoolInfo, setSchoolInfo]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
        
       totalOpen:0,
    }); 
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const [studentList, setStudentList] = useState([]);
    const [attendance, setAttendance] = useState({
       sectionID:[],
       fromDate:'',
       toDate:'',
       examtype:[],
       student:[],
       
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'},
    })

    const fetchSchoolInfo =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }



    const fetchStudents =()=>{
        let url = API_URL+'/students/all';
        axios.get(url,  config).then(result=>{
            if(result.data.data.length!==0){
                setStudentList(result.data.data)
                }
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
       
        
        const handleSelect= (option, action)=>{
            setAttendance({...attendance, [action.name]:option});

            if(action.name==='examtype'){
                getDaysBetween(option.startDate, option.endDate)
            }
            setErrors({...errors, [action.name]:''})
            setResult('')
        }

     
        const getAttendance =(startDate, endDate)=>{
            let url = API_URL+'/student/attendance/statistics/'+startDate+'/'+endDate
            axios.get(url, config).then(response=>{
                if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                    fetchAllHolidays(response.data.holiday, startDate, endDate)
                }
            })
            .catch((error)=>{
            //Alerts('Error!', 'danger', error.message)
        })
    }

    const fetchAllHolidays =(holiday, startDate, endDate)=>{
        var total = parseFloat(getNumWorkDays(startDate, endDate));
        if(Array.isArray(holiday)&& holiday.length!==0){

            setNotice({...notice, totalOpen:(total - Number(holiday.data[0].total))*2 })
            
            }else{
                setNotice({...notice, totalOpen:total*2 })
            }
    }


        
        const getDaysBetween =(startDate, endDate)=>{
            getAttendance(startDate, endDate) 
            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            while (start <= end){
                result.push(new Date(start).toISOString().slice(0,10))
                start.setDate(start.getDate()+1)
            }

           
            let final =   'SELECT s.studentName, s.admissionNumber, sum(case when a.indicator  = "P" then 2 else 0 end) as P, sum(case when a.indicator  = "H" then 1 else 0 end) as H, sum(case when a.indicator  = "A" then 2 else 0 end) as A from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode and a.attendanceDate >= "'+startDate+'" and a.attendanceDate <= "'+endDate+'"'

            setSqlQuery(final)
        }
        
       
    
            const fetchResult =()=>{
                    let sql = sqlQuery

            if(attendance.student.length!==0){
                sql = sql + '  and a.studentCode = "'+attendance.student.value+'"'
            }
            if(attendance.sectionID.length!==0){
                sql = sql + '  and a.sectionID = "'+attendance.sectionID.value+'"'
            }

            sql = sql+ '  group by a.studentCode '+attendance.orderBy.value
            
      setNotice({...notice, isLoading: true}) 
      const fd = new FormData();
      fd.append("sql", sql);
     let url = API_URL+'/exam/result/fetch_raw';
            axios.post(url, fd, config).then(result=>{
                if(result.data.data.length!==0){
                    setResult(result.data.data)
                }else{
                    setResult([])
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })  
        } 
        


            const validateInput =(values)=>{
                let errors = {};     
                
                if(values.examtype.length===0){
                    errors.examtype ='This field is required';
                }
                return errors;
                }
            
                const fetchExamType =()=>{

                    var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.ID, r.code, r.startDate, r.endDate, r.nextTermBegin, t.code as ecode from tbl_result_attendance r, tbl_exam_type t where r.exam = t.code order by exam DESC" ;
                    
                    const fd = new FormData();
                    fd.append("sql", sql);
                   let url = API_URL+'/exam/result/fetch_raw';
                    axios.post(url, fd, config).then(result=>{
                        setExamtype(result.data.data)
                    })
                    .catch((error)=>{
                        Alerts('Error!', 'danger', error.message)
                    })
                    }

               /*  const fetchExamType =()=>{
       
                    let url = API_URL+'/exam/result/examtype';
                    axios.get(url,  config).then(result=>{
                        if(result.data.examType.length!==0){
                            setExamtype(result.data.examType)
                            }
                    
                    })
                    .catch((error)=>{
                        Alerts('Error!', 'danger', error.message)
                    })
                    } */




            const handleSubmit =(event)=>{
                event.preventDefault();
                setErrors(validateInput(attendance));
                setIsSubmitting(true);
            } 


            useEffect(()=>{
                if(Object.keys(errors).length === 0 && isSubmitting){
                fetchResult()
                }
            },[errors])
     
     useEffect(()=>{
        fetchExamType()
        fetchSchoolInfo()
        fetchStudents()
    },[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Attendance Summary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Attendance Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Student Attendance Summary</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
            

        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
            <div className="form-group">
          <label> Student Class</label>
          <Select  options={
                             schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                         } 
                         formatGroupLabel={formatGroupLabel}
 onChange={handleSelect} className={errors.sectionID ? 'form-control form-control-danger' : ''} name="sectionID" value={attendance.sectionID}  /> <span style={{color:'red'}}>{errors.sectionID && errors.sectionID}</span>
             </div>

      </section>


      <section className="col-md-6" >
<div className="form-group">
                  <label>Select Student </label>
                  <Select  options={
                      studentList.map((list, idx)=> {
                         return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, studentName:list.studentName }
                       })} 
                  getOptionLabel={option =>option.label +' '+ option.studentName+' c/o '+option.fatherName+' '+option.mobile}

onChange={handleSelect}  name="student" value={attendance.student}  /> 
                                      </div>

</section>


<section className="col-md-6">
     <div className="form-group">
          <label> Attendance Term <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype.map((list, id)=>{
             return {key:id, value:list.code, label:list.exam, startDate:list.startDate, endDate:list.endDate}
         })} 
         getOptionLabel={option =>option.label + ' '+ longDate(option.startDate)+" - "+longDate(option.endDate)}               
 onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={attendance.examtype} id='exam'  />

 <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>	
     

<section className="col-md-6">
            <div className="form-group">
          <label> Order</label>
          <Select  options={[
             {value:'order by s.studentName ASC', label:'Order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={attendance.orderBy}  /> 

 
             </div>
      
      </section>


				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-search"></i> Search Attendance</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={'schoolName'}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Student Attendance Summary</b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{attendance.sectionID.label}</b></td>
                    <td >Date Range: <b>{longDate(attendance.examtype.startDate) + ' - ' +longDate(attendance.examtype.endDate)}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                <th >Admission No</th>
                    <th className='fixed-column'>Student Name</th>
                    <th>Total Attendance</th>
                    <th>Total Present</th>
                    <th>Total Half Day</th>
                    <th>Total Absent</th>
                    <th>Present (%)</th>
                    <th>Half Day (%)</th>
                    <th>Absent (%)</th>
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td >{st.admissionNumber}</td>
                    <td className='fixed-column'>{st.studentName}</td>
                    <td>{notice.totalOpen}</td>
                    <td>{st.P}</td>
                    <td>{st.H}</td>
                    <td>{Number(notice.totalOpen)-(Number(st.P)+Number(st.H))}</td>


                    <td>{((100*Number(st.P)) / Number(notice.totalOpen)).toFixed(2)}</td>
                    <td>{((100*Number(st.H)) / Number(notice.totalOpen)).toFixed(2)}</td>
                    <td>{((100*(Number(notice.totalOpen)-(Number(st.P)+Number(st.H)))) / Number(notice.totalOpen)).toFixed(2)}</td>
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/student_attendance.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='dateRange' value ={longDate(attendance.examtype.startDate) + ' - ' +longDate(attendance.examtype.endDate)} />
                <input type='hidden' name='sectionID' value ={attendance.sectionID.label} />
                <input type='hidden' name='totalOpen' value ={notice.totalOpen} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(DisplayStudentAttendance) 